export default {
  // This is the list of languages your application supports
  supportedLngs: ["en", "bg", "da", "de", "es", "fr", "id", "it", "ms", "nl", "ro", "vi", "zh-Hant"],
  // This is the language you want to use in case
  // if the user language is not in the supportedLngs
  // We handle this in getLocale() in helper.server.ts
  fallbackLng: false as false,
  // The default namespace of i18next is "translation", but you can customize it here
  defaultNS: "orderFlow",
  // Disabling suspense is recommended
  react: { useSuspense: false },
};
