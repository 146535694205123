// reference: https://dev.to/remix-run-br/type-safe-environment-variables-on-both-client-and-server-with-remix-54l5
type publicEnvKeys =
  | "POSTCO_APP_SERVER_URL"
  | "OLD_CUSTOMER_APP_URL"
  | "SENTRY_DSN"
  | "REMIX_APP_ENVIRONMENT"
  | "STRIPE_MY_PUBLISHABLE_KEY"
  | "STRIPE_SG_PUBLISHABLE_KEY"
  | "NEW_CUSTOMER_APP_URL";

export function getPublicEnv(key: publicEnvKeys): string | undefined {
  return typeof window === "undefined" ? process.env[key] : (window.ENV as { [key: string]: string })[key];
}

export const snakeToCamelCase = (str: string) => str.replace(/([-_]\w)/g, (g) => g[1].toUpperCase());

export function parseTranslationWithArgs(translation: string, args: Record<string, string>) {
  return Object.keys(args).reduce((acc, key) => {
    return acc.replace(`{{${key}}}`, args[key]);
  }, translation);
}
