import { withSentry } from "@sentry/remix";
import { LinksFunction, MetaFunction } from "@remix-run/node";
import { json } from "@remix-run/node";
import { useLoaderData } from "@remix-run/react";
import { Links, Meta, Outlet, Scripts, ScrollRestoration } from "@remix-run/react";
import { GeneralErrorBoundary } from "./components/GeneralErrorBoundary";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useState } from "react";
import styles from "./tailwind.css?url";
import { honeypot } from "~/honeypot.server";
import { HoneypotProvider } from "remix-utils/honeypot/react";
import { ExternalScripts } from "remix-utils/external-scripts";
import { Toaster } from "@/components/ui/toaster";

export const links: LinksFunction = () => [
  {
    rel: "preconnect",
    href: "https://fonts.googleapis.com",
  },
  { rel: "preconnect", href: "https://fonts.gstatic.com", crossOrigin: "anonymous" },
  { rel: "stylesheet", href: styles },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap",
  },
];

export const meta: MetaFunction = (_data) => {
  return [
    { title: "PostCo: Returns Center" },
    { name: "viewport", content: "width=device-width, initial-scale=1" },
    { charSet: "UTF-8" },
    { httpEquiv: "X-UA-Compatible", content: "ie=edge" },
  ];
};

// this makes window.ENV type safe
declare global {
  interface Window {
    ENV: {
      POSTCO_APP_SERVER_URL: string;
      OLD_CUSTOMER_APP_URL: string;
      OLD_EMBED_CUSTOMER_APP_URL: string;
      SENTRY_DSN: string;
      REMIX_APP_ENVIRONMENT: string;
      STRIPE_MY_PUBLISHABLE_KEY: string;
      STRIPE_SG_PUBLISHABLE_KEY: string;
      NEW_CUSTOMER_APP_URL: string;
    };
  }
}

// setting browser env based on this https://remix.run/docs/en/main/guides/envvars#browser-environment-variables
export async function loader() {
  return json({
    honeypotInputProps: honeypot.getInputProps(),
    ENV: {
      POSTCO_APP_SERVER_URL: process.env.POSTCO_APP_SERVER_URL,
      OLD_CUSTOMER_APP_URL: process.env.OLD_CUSTOMER_APP_URL,
      OLD_EMBED_CUSTOMER_APP_URL: process.env.OLD_EMBED_CUSTOMER_APP_URL,
      SENTRY_DSN: process.env.SENTRY_DSN,
      REMIX_APP_ENVIRONMENT: process.env.REMIX_APP_ENVIRONMENT,
      STRIPE_MY_PUBLISHABLE_KEY: process.env.STRIPE_MY_PUBLISHABLE_KEY,
      STRIPE_SG_PUBLISHABLE_KEY: process.env.STRIPE_SG_PUBLISHABLE_KEY,
      NEW_CUSTOMER_APP_URL: process.env.NEW_CUSTOMER_APP_URL,
    },
  });
}

function Document({ children, env = {} }: { children: React.ReactNode; env?: Record<string, string> }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <Meta />
        <Links />
        <ExternalScripts />
      </head>
      <body>
        {/* The div below is to make sure the Popover from HeadlessUI close if click outside Popover */}
        <div>
          {children}
          <script
            dangerouslySetInnerHTML={{
              __html: `window.ENV = ${JSON.stringify(env)}`,
            }}
          />
          <Toaster />
          <ScrollRestoration />
          <Scripts />
        </div>
      </body>
    </html>
  );
}

function App() {
  const { ENV, honeypotInputProps } = useLoaderData<typeof loader>();

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // With SSR, we usually want to set some default staleTime
            // above 0 to avoid refetching immediately on the client
            staleTime: 60 * 1000,
          },
        },
      }),
  );

  return (
    <Document env={ENV}>
      <QueryClientProvider client={queryClient}>
        <HoneypotProvider {...honeypotInputProps}>
          <Outlet />
        </HoneypotProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Document>
  );
}

export default withSentry(App);

export function ErrorBoundary() {
  return (
    <Document>
      <GeneralErrorBoundary />
    </Document>
  );
}
